:root {
  --cl-black: #191c1f;
  --cl-grey_light: #f7f7f7;
  --cl-grey_mid: #edeff2;
  --cl-grey_mid2: #dfe2e6;
  --cl-grey_dark: #758089;
  --cl-white: #ffffff;
  --cl-spot: #5374fc;
  --cl-spot_light: #e6eaf8;
  --cl-caution: #fc5352;
  --cl-caution_light: #f8e6e6;

  --br-outer: 15px;
  --br-inner: 8px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Roboto Flex', sans-serif;
  font-variation-settings: 'wght' 500;
  letter-spacing: -0.01em;
  color: var(--cl-black);
  background-color: var(--cl-grey_light);
}

.dialog-title-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* width */
/*::-webkit-scrollbar {*/
/*  width: 14px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*  background: var(--cl-grey_light);*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*  background: var(--scroll-handle-color);*/
/*  !* border-radius: 10px; *!*/
/*}*/
h1 {
  /*    font-weight: 900;*/
  font-variation-settings: 'wght' 700;
  /*    transition: font-variation-settings 0.4s cubic-bezier(.41, .01, .39, 1);*/
}

html {
  color-scheme: light;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  box-sizing: border-box;
  color-scheme: only light;
}

a {
  text-decoration: none;
}

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*  background: var(--scroll-handle-color-hover);*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #edeff2 inset !important;
}
