.tour-client-logo-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  /*background: green;*/
}

.tour-client-logo-image {
  width: 50%;
  max-width: 350px;
  padding: 20px;
  min-width: 100px;
  max-height: 175px;
  object-fit: contain;
  pointer-events: none;
}
