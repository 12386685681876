.cls-1 {
  opacity: 0.25;
  stroke-width: 0;
}

.cls-2 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-2,
.cls-3 {
  fill: none;
  stroke-width: 7px;
}

.cls-3 {
  stroke-miterlimit: 10;
}
