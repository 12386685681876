.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  &.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/*.truncate*/

@media screen and (max-width: 900px) {
  .truncate {
    /*display: -webkit-box;*/
    -webkit-line-clamp: 1;
    /*-webkit-box-orient: vertical;*/
    /*overflow: hidden;*/
  }
}
